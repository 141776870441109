<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card ref="formCard">
        <v-card-title>
          <span class="headline" v-if="formType === 'UPDATE'"
            >Update Client</span
          >
          <span class="headline" v-else>Create New Client</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="formEntry">
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-select
                    v-model="form.legalFormId"
                    :items="legalFormItems"
                    item-text="text"
                    item-value="value"
                    label="Client Type*"
                  ></v-select>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    :label="`${
                      form.legalFormId === 1 ? 'Last Name*' : 'Name*'
                    }`"
                    v-model="form.lastname"
                    required
                    :rules="[rules.requiredField]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    label="First Name*"
                    v-model="form.firstname"
                    required
                    :rules="[rules.requiredField]"
                    v-if="form.legalFormId === 1"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    label="Middle Name"
                    v-model="form.middlename"
                    v-if="form.legalFormId === 1"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    label="External Code*"
                    v-model="form.externalId"
                    required
                    :rules="[rules.requiredField]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    label="TIN*"
                    v-model="form.tin"
                    required
                    :rules="[rules.requiredField]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    label="Contact number*"
                    v-model="form.mobileNo"
                    required
                    :rules="[rules.requiredField]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    label="Email*"
                    v-model="form.emailAddress"
                    required
                    :rules="[rules.requiredField, rules.properEmail]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4 v-if="formType === 'UPDATE'">
                  <v-switch
                    v-model="form.active"
                    :label="`Is Enabled: ${
                      form.active ? form.active.toString() : 'false'
                    }`"
                  ></v-switch>
                </v-flex>
                <v-flex xs12 v-if="formType !== 'UPDATE'">
                  <v-autocomplete
                    v-model="form.officeId"
                    :items="departmentList"
                    item-text="name"
                    item-value="id"
                    label="Department*"
                    required
                    :rules="[rules.requiredField]"
                  ></v-autocomplete>

                  <v-flex xs12>
                    <v-switch
                      label="Create a User Account?*"
                      v-model="form.createUser"
                      requred="false"
                    ></v-switch>
                  </v-flex>

                  <v-flex xs12 v-if="form.createUser == true">
                    <v-text-field
                      label="Username*"
                      v-model="form.mobileNo"
                      disabled
                      required
                      :rules="[rules.requiredField]"
                    ></v-text-field>
                    <v-text-field
                      label="Password*"
                      v-model="form.password"
                      required
                      :rules="[rules.requiredField]"
                      data-cy="form-password"
                      type='password'
                    ></v-text-field>

                    <v-flex xs12>
                      <v-select
                        v-model="form.userType"
                        data-cy="user-type"
                        :items="userTypeList"
                        item-text="name"
                        item-value="name"
                        required
                        :rules="[rules.requiredSelection]"
                        label="User Type*"
                      ></v-select>
                    </v-flex>
                    <v-flex xs12>
                      <v-autocomplete
                        :items="roleList"
                        item-text="name"
                        item-value="name"
                        label="Groups*"
                        data-cy="group-name"
                        v-model="form.role"
                        required
                        :rules="[rules.requiredSelection]"
                        chips
                        deletable-chips
                        multiple
                        return-object
                      >
                        <v-list-item
                          slot="prepend-item"
                          ripple
                          @click="toggle"
                          v-if="roleList.length > 0"
                        >
                          <v-list-item-action>
                            <v-icon
                              :color="
                                form.role.length > 0 ? 'indigo darken-4' : ''
                              "
                              >{{ icon }}</v-icon
                            >
                          </v-list-item-action>
                          <v-list-item-title>Select All</v-list-item-title>
                        </v-list-item>
                        <v-divider slot="prepend-item" class="mt-2"></v-divider>
                        <v-divider slot="append-item" class="mb-2"></v-divider>
                      </v-autocomplete>
                    </v-flex>
                  </v-flex>
                </v-flex>
                <v-flex xs12 v-if="formType === 'UPDATE'">
                  <v-text-field
                    label="Department"
                    v-model="form.departmentName"
                    readonly="readonly"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text v-on:click="close">Close</v-btn>
          <v-btn color="blue darken-1" text v-on:click="save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import API from "@/api/API.js";
export default {
  props: {
    isShow: Boolean,
    formType: String,
    loadingDialog: Boolean,
    selectedItem: Object,
    departments: Array,
    roles: Array,
  },
  computed: {
    populatedForm() {
      return {
        id: this.form.id,
        legalFormId: this.form.legalFormId,
        firstname: this.form.firstname,
        middlename: this.form.middlename,
        lastname: this.form.lastname,
        externalId: this.form.externalId,
        tin: this.form.tin,
        active: this.form.active,
        mobileNo: this.form.mobileNo,
        emailAddress: this.form.emailAddress,
        officeId: this.form.officeId,
        role: this.formatRoleList(true, this.form.role),
        formType: this.formType,
        createUser: this.form.createUser,
        password: this.form.password,
        confirmPassword: this.form.confirmPassword,
        username: this.form.username,
        userType: this.form.userType,
      };
    },
    haveAllRole() {
      return this.form.role.length === this.roleList.length;
    },
    selectedRole() {
      return this.form.role.length > 0 && !this.haveAllRole;
    },
    icon() {
      if (this.haveAllRole) return "mdi-close-box";
      if (this.selectedRole) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    isShow: {
      handler(value) {
        this.dialog = value;
      },
    },
    loadingDialog: {
      handler(value) {
        this.loading = value;
      },
    },
    dialog: {
      handler(value) {
        if (value === true) {
          this.initializeForm();
        }
      },
    },
    roles: {
      handler(value) {
        this.roleList = value ? value : ["GROUP_SUPER_ADMIN", "GROUP_SYSADMIN"];
      },
    },
    departments: {
      handler(value) {
        this.departmentList = value ? value : [];
      },
    },
    deep: true,
    options: {
      hander() {
        // this.setData();
      },
    },
  },
  data() {
    return {
      legalFormItems: [
        { text: "PERSON", value: 1 },
        { text: "ENTITY", value: 2 },
      ],
      roleList: this.roles
        ? this.formatRoleList(false, this.roles)
        : ["GROUP_SUPER_ADMIN", "GROUP_SYSADMIN"],
      userTypeList: [],
      departmentList: this.departments ? this.departments : [],
      dialog: this.isShow ?? false,
      loading: this.loadingDialog ?? false,
      form: {
        role: [],
      },
      passwordType: {
        password: String,
        confirmPassword: String,
      },
      rules: {
        requiredField: (v) => !!v || "Required field.",
        requiredSelection: (v) =>
          (!!v && v.length > 0) || "Required at least one selection",
        properEmail: (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid. Ex. juandelacruz@apollo.com.ph",
      },
    };
  },
  mounted() {
    this.initializeForm();
    this.getUserTypes();
    // this.setData();
    // this.setData();
  },
  methods: {
    async getUserTypes() {
      try {
        this.loading = true;
        const listResponse = await API.getUserTypes();
        if (!listResponse || listResponse.error) {
          //error getting data
          console.log(`${listResponse.error}`);
        } else {
          this.userTypeList = listResponse;
          this.loading = false;
          return this.userTypeList;
        }
      } catch (e) {
        console.log(e);
        //display error prompt
        this.loading = false;
        this.userTypeList = [];
        return this.userTypeList;
      }
    },
    async setData() {
      try {
        const listResponse = await API.getAllRoles();
        if (!listResponse || listResponse.error) {
          //error getting data
          console.log(`${listResponse.error}`);
        } else {
          this.roles = this.formatRoleList(false, listResponse.roles)
          console.log("ROLES HERE");
          console.log(listResponse.roles[0]);
          return this.roles
        }
      } catch (e) {
        console.log(e);
      }
    },
    close() {
      this.dialog = false;
      this.$refs.formEntry.reset();
      this.$refs.formCard.$el.scrollIntoView({
        behavior: "smooth",
      });
      this.$emit("close-dialog", this.formType);
    },
    save() {
      if (this.$refs.formEntry.validate()) {
        this.$emit("save-member", this.populatedForm);
        this.$refs.formCard.$el.scrollIntoView({
          behavior: "smooth",
        });
      }
    },
    resetForm() {
      this.$refs.formEntry.reset();
    },
    formatRoleList(isPopulated, list) {
      if (isPopulated) {
        try {
          return list.replace(/"/g, '"');
        } catch (e) {
          return list;
        }
      } else {
        try {
          return list.map(({ role }) => ({ name: role }));
        } catch (e) {
          return list;
        }
      }
    },
    toggle() {
      this.$nextTick(() => {
        if (this.haveAllRole) {
          this.form.role = [];
        } else {
          this.form.role = this.roleList.slice();
        }
      });
    },
    initializeForm() {
      if (this.formType === "CREATED") {
        this.form = {
          legalFormId: 1,
          firstname: undefined,
          middlename: undefined,
          lastname: undefined,
          externalId: undefined,
          tin: undefined,
          active: undefined,
          mobileNo: undefined,
          emailAddress: undefined,
          officeId: undefined,
          password: undefined,
          confirmPassword: undefined,
          username: undefined,
          userType: undefined,
          role: [],
        };
      } else if (this.formType === "UPDATE" && this.selectedItem) {
        if (this.selectedItem.legalForm.id === 1) {
          this.form = {
            id: this.selectedItem.id,
            legalFormId: this.selectedItem.legalForm.id,
            firstname: this.selectedItem.firstname,
            middlename: this.selectedItem.middlename,
            lastname: this.selectedItem.lastname,
            externalId: this.selectedItem.externalCode,
            tin: this.selectedItem.tin,
            active: this.selectedItem.status.value === "Active" ? true : false,
            mobileNo: this.selectedItem.mobileNo,
            emailAddress: this.selectedItem.emailAddress,
            departmentName: this.selectedItem.departmentName,
          };
        } else {
          this.form = {
            id: this.selectedItem.id,
            legalFormId: this.selectedItem.legalForm.id,
            lastname: this.selectedItem.fullname,
            externalId: this.selectedItem.externalCode,
            tin: this.selectedItem.tin,
            active: this.selectedItem.status.value === "Active" ? true : false,
            mobileNo: this.selectedItem.mobileNo,
            emailAddress: this.selectedItem.emailAddress,
            departmentName: this.selectedItem.departmentName,
          };
        }
      }
    },
  },
};
</script>
