<template>
  <v-container row justify-center>
    <v-dialog scrollable width="500px" persistent v-model="isShowDetails">
      <v-card>
        <v-card-title class="headline">
          {{ details.title }}
        </v-card-title>
        <v-card-text>
          <v-row v-for="(field, index) of Object.entries(details.item)" :key="index">
            <v-col cols="12" v-if="isJSON(field[1])">
              <span>{{ convertCamelCase(field[0]).toUpperCase() }}</span>
              <v-spacer>
                <v-data-table
                  v-if="field[1].length > 0"
                  :hide-default-header="true"
                  :headers="headers"
                  :items="getItems(field[1])"
                  class="elevation-1"
                >
                  <template v-slot:header="{ props: { headers } }">
                    <thead>
                      <tr :colspan="headers.length">
                        <th v-for="header in headers" :key="header.text">
                          <span class="d-flex justify-center">{{ header.text }}</span>
                        </th>
                      </tr>
                    </thead>
                  </template>
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                        <td>
                          <span class="d-flex justify-center"> {{ item[0].value }} </span>
                        </td>
                        <td>
                          <span class="d-flex justify-center"> {{ item[1].value }} </span>
                        </td>
                        <td>
                          <span class="d-flex justify-center"> {{ item[2].value }} </span>
                        </td>
                        <td>
                          <span class="d-flex justify-center"> {{ item[3].value }} </span>
                        </td>
                        <td>
                          <span class="d-flex justify-center"> {{ item[5].value }} </span>
                        </td>
                        <td>
                          <span class="d-flex justify-center"> {{ item[7].value }} </span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-spacer>
              <v-divider class="divider"></v-divider>
            </v-col>
            <v-col cols="12" v-else>
              <v-text-field
                :value="stringify(field[1])"
                :label="convertCamelCase(field[0]).toUpperCase()"
                :data-cy="field[0]"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="close"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  props: {
    isShow: Boolean,
    details: Object,
  },
  watch: {
    isShow: {
      handler(value) {
        this.isShowDetails = value;
      },
    },
    deep: true,
  },
  data() {
    return {
      isShowDetails: this.isShow ?? false,
      headers: [],
    };
  },
  async mounted() {
    this.setHeaders();
  },
  methods: {
    convertCamelCase(string) {
      return string.replace(/([a-z])([A-Z])/g, "$1 $2");
    },
    close() {
      this.isShowDetails = false;
      this.$emit("close-dialog", "details");
    },
    isJSON(value) {
      return (
        Object.prototype.toString.call(value) === "[object Array]" ||
        Object.prototype.toString.call(value) === "[object Object]"
      );
    },
    stringify(value) {
      if (Object.prototype.toString.call(value) === "[object Array]") {
        var items = [];
        for (var index in value) {
          if (value[index]) {
            items.push(value[index]);
          }
        }
        if (items.length > 0) {
          return items.toString();
        }
        return JSON.stringify(value);
      } else if (Object.prototype.toString.call(value) === "[object Object]") {
        if (value.role) {
          return value.role;
        }
        return JSON.stringify(value);
      } else {
        return value.toString();
      }
    },

    getItems(value) {
      if (Object.prototype.toString.call(value) === "[object Array]") {
        var data = [];
        var num = 0;
        for (var index in value) {
          data[num] = [];
          var numIndex = 0;
          for (var valueIndex in value[index]) {
            data[num][numIndex] = {};
            data[num][numIndex]["value"] = value[index][valueIndex];
            data[num][numIndex]["index"] = valueIndex;
            numIndex++;
          }
          num++;
        }
        return data;
      }
    },
    setHeaders() {
      var headers = [
        {
          text: "Job Name",
          value: "jobName",
        },
        {
          text: "Calculation",
          value: "calculation",
        },
        {
          text: "Schedule",
          value: "schedule",
        },
        {
          text: "Amount",
          value: "amount",
        },
        {
          text: "Time Type",
          value: "timeType",
        },
        {
          text: "Job Type",
          value: "jobType",
        },
      ];
      this.headers = headers;
    },
  },
};
</script>
<style scoped>
.divider {
       border-color: #94928f !important;
       width: 100%;
}
</style>
