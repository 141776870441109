<template>
  <v-app id="inspire">
    <div class="px-4">
      <loading
        :is-show="loadingDialog"
        :is-done="loadingFinished"
        :is-html="isHtml"
        :message="loadingMessage"
        v-on:close-dialog="closeDialog"
      />
      <details-form
        :is-show="isShowDetails"
        :details="details"
        v-on:close-dialog="closeDialog"
      />
      <v-container class="container--fluid grid-list-md text-center">
        <h1>Clients</h1>
      </v-container>
      <v-card elevation="2" outlined shaped tile class="pa-1">
        <div class="d-flex flex-no-wrap justify-space-between">
          <v-card-title>
            <v-container grid-list-md text-xs-center>
              <v-layout row wrap>
                <v-flex xs12 sm6>
                  <v-text-field
                    label="External Id"
                    v-model="query.externalId"
                    outlined
                    clearable
                    @change="search($event, 'externalId')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    label="First Name"
                    v-model="query.firstName"
                    outlined
                    clearable
                    @change="search($event, 'firstName')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    label="Last Name"
                    v-model="query.lastName"
                    outlined
                    clearable
                    @change="search($event, 'lastName')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    label="Display Name"
                    v-model="query.displayName"
                    outlined
                    clearable
                    @change="search($event, 'displayName')"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-title>
        </div>
        <div class="d-flex flex-no-wrap justify-space-between">
          <v-card-title>
            <v-container grid-list-md text-xs-center>
              <v-layout wrap>
                <v-flex xs12 sm6>
                  <v-btn
                    :block="$vuetify.breakpoint.mdAndDown"
                    class="mt-2"
                    color="success"
                    @click="queryData(true)"
                  >
                    <v-icon dark left> mdi-magnify</v-icon> Search
                  </v-btn>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-btn
                    :block="$vuetify.breakpoint.mdAndDown"
                    class="mt-2"
                    color="success"
                    @click.stop="showMemberForm('CREATED')"
                    v-if="hasPermission('ROLE_CREATE_MEMBER')"
                    >Create Client</v-btn
                  >
                  <member-form
                    ref="form"
                    :selected-item="selectedMember"
                    :is-show="showFormDialog"
                    :form-type="memberFormType"
                    :departments="departments"
                    v-on:close-dialog="closeDialog"
                    v-on:save-member="saveMember"
                    :roles="roles"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-title>
        </div>
      </v-card>

      <v-data-table
        :hide-default-header="true"
        :headers="headers"
        :items="displayMembers"
        :loading="loading"
        loading-text="Loading... Please wait"
        :options.sync="options"
        :server-items-length="totalMembers"
      >
        <template v-slot:header="{ props: { headers } }">
          <thead>
            <tr :colspan="headers.length">
              <th :class="`${header.class}`" v-for="header in headers" :key="header.text">
                <span class="d-flex justify-center">{{ header.text }}</span>
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:body="{ items }">
          <tbody>
            <tr :colspan="headers.length" v-for="item in items" :key="item.id">
              <td>
                <span class="d-flex justify-center">
                  {{ item.firstname }} {{ item.lastname }} {{ item.fullname }}
                </span>
              </td>
              <td class="hidden">
                <span class="d-flex justify-center"> {{ item.firstname }} </span>
              </td>
              <td class="hidden">
                <span class="d-flex justify-center"> {{ item.lastname }} </span>
              </td>
              <td>
                <span class="d-flex justify-center"> {{ item.externalCode }} </span>
              </td>
              <td>
                <span class="d-flex justify-center"> {{ item.dateCreated }} </span>
              </td>
              <td>
                <span class="d-flex justify-center"> {{ item.lastUpdated }} </span>
              </td>
              <td>
                <span class="d-flex justify-center"> {{ item.legalForm.value }} </span>
              </td>
              <td>
                <span class="d-flex justify-center"> {{ item.status.value }} </span>
              </td>
              <td>
                <span class="d-flex justify-center">
                  <v-btn
                    fab
                    dark
                    x-small
                    color="green"
                    class="mr-2"
                    @click.stop="viewDetails(item)"
                  >
                    <v-icon dark medium>mdi-magnify-plus</v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    dark
                    x-small
                    color="green"
                    @click.stop="showMemberForm('UPDATE', item)"
                    v-if="hasPermission('ROLE_UPDATE_MEMBER')"
                  >
                    <v-icon dark medium>mdi-pencil</v-icon>
                  </v-btn>
                </span>
              </td>
            </tr>
          </tbody>
        </template>
        <template v-slot:no-results>
          <v-alert :value="true" color="error" icon="warning">
            Found no results.
          </v-alert>
        </template>
      </v-data-table>
    </div>
  </v-app>
</template>

<script>
import API from "@/api/API.js";
import Loading from "@/views/components/Loading.vue";
import MemberForm from "@/views/components/MemberForm.vue";
import DetailsForm from "@/views/components/DetailsForm.vue";
import { mapGetters } from "vuex";

export default {
  name: "Members",
  components: {
    Loading,
    MemberForm,
    DetailsForm,
  },
  data() {
    return {
      headers: [],
      displayMembers: [],
      loading: true,
      loadingMessage: "",
      loadingDialog: false,
      loadingFinished: false,
      memberFormType: "CREATE",
      selectedMember: {},
      departments: [],
      totalMembers: 0,
      options: {},
      details: {
        title: "",
        item: {},
      },
      query: {
        externalId: "",
        firstName: "",
        lastName: "",
        displayName: "",
      },
      isShowDetails: false,
      showFormDialog: false,
      isHtml: false,
      roles: [],
    };
  },
  watch: {
    options: {
      handler() {
        this.queryData();
        this.setDataRoles();
        this.setData();
      },
    },
    roles: {
      handler(value) {
        this.roleList = value ? value : ["GROUP_SUPER_ADMIN", "GROUP_SYSADMIN"];
      },
    },
    deep: true,
  },
  computed: {
    //get role attributes
    ...mapGetters(["attributes", "user", "authorities"]),
  },
  async mounted() {
    this.setHeaders();
    this.queryData();
    
  },
  methods: {
    hasPermission(authority) {
      if (this.authorities.some((x) => x === authority)) {
        return true;
      }
      return false;
    },
    async queryData(isSearch = false) {
      this.loading = true;
      if (!this.displayMembers.length) {
        this.loadingDialog = true;
      }
      if (isSearch) {
        this.options.page = 1;
      }
      let offset = (this.options.page - 1) * this.options.itemsPerPage;
      try {
        const memberListResponse = await API.searchMembers(
          offset,
          this.options.itemsPerPage,
          this.query
        );
        console.log(memberListResponse);
        if (!memberListResponse || memberListResponse.error) {
          //error getting data
          console.log(`${memberListResponse.error}`);
        } else {
          this.totalMembers = memberListResponse.totalSize;
          this.displayMembers = memberListResponse.members;
        }
        this.loading = false;
        this.loadingDialog = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async setData() {
      try {
        const listResponse = await API.getDepartments();
        if (!listResponse || listResponse.error) {
          //error getting data
          console.log(`${listResponse.error}`);
        } else {
          // convert object to array
          this.departments = Object.keys(listResponse).map((key) => {
            return listResponse[key];
          });

          console.log(this.departments);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async setDataRoles() {
      try {
        const listResponse = await API.getAllRoles();
        console.log("Set Data Roles ")
        if (!listResponse || listResponse.error) {
          //error getting data
          console.log(`${listResponse.error}`);
        } else {
          this.roles = listResponse.roles
          console.log("ROLES HERE");
          console.log(listResponse.roles[0]);
          return this.roles
        }

      } catch (e) {
        console.log(e);
      }
    },
    setHeaders() {
      var headers = [
        {
          text: "Name",
          value: "fullname",
        },
        {
          text: "Fist Name",
          value: "firstname",
          class: "hidden",
        },
        {
          text: "Last Name",
          value: "lastname",
          class: "hidden",
        },
        {
          text: "External Code",
          value: "externalCode",
        },
        {
          text: "Date Created",
          value: "dateCreated",
        },
        {
          text: "Last Updated",
          value: "lastUpdated",
        },
        {
          text: "Legal Form",
          value: "legalForm.value",
        },
        {
          text: "Status",
          value: "status.value",
        },
        {
          text: "Actions",
          value: "controls",
          sortable: false,
        },
      ];
      this.headers = headers;
    },
    showMemberForm(what, item = {}) {
      this.memberFormType = what;
      this.selectedMember = item;
      this.showFormDialog = true;
    },
    closeDialog(type) {
      if (type === "details") {
        this.details.title = "";
        this.details.item = {};
        this.isShowDetails = false;
      } else if (type === "CREATED" || type === "UPDATE") {
        this.showFormDialog = false;
      } else if (type === "loading") {
        this.loadingDialog = false;
        this.$router.go(0);
      } else if (type === "loading-error") {
        this.loadingDialog = false;
      }
    },
    async saveMember(value) {
      this.loadingDialog = true;
      this.isHtml = true;
      this.loadingFinished = false;
      if (value) {
        if (value.formType === "CREATED") {
          this.loadingMessage = `Creating new client <strong><i>${
            value.firstname ? value.firstname + " " + value.lastname : value.lastname
          }</i></strong>`;
          setTimeout(async () => {
            const createResponse = await API.createMembers(value);
            this.loadingFinished = true;
            if (!createResponse || createResponse.error) {
              this.isHtml = false;
              this.loadingMessage = createResponse.error.message;
            } else {
              this.loadingMessage = `Successfully created client <strong><i>${
                value.firstname ? value.firstname + " " + value.lastname : value.lastname
              }</i></strong>`;
              this.showFormDialog = false;
              this.$refs.form.resetForm();
            }
          }, 2000);
        } else if (value.formType === "UPDATE") {
          this.loadingMessage = `Updating client <strong><i>${
            value.firstname ? value.firstname + " " + value.lastname : value.lastname
          }</i></strong>`;
          setTimeout(async () => {
            const updateResponse = await API.updateMembers(value);
            this.loadingFinished = true;
            if (!updateResponse || updateResponse.error) {
              this.isHtml = false;
              this.loadingMessage = updateResponse.error.message;
            } else {
              this.loadingMessage = `Successfully updated client <strong><i>${
                value.firstname ? value.firstname + " " + value.lastname : value.lastname
              }</i></strong>`;
              this.showFormDialog = false;
              this.$refs.form.resetForm();
            }
          }, 2000);
        } else {
          this.loadingMessage = "Invalid command";
          this.loadingFinished = true;
        }
      } else {
        this.loadingMessage = "Error processing data.";
        this.loadingFinished = true;
      }
    },
    search(event, type) {
      this.query[type] = event;
    },
    viewDetails(details) {
      this.details.title = details.externalCode;
      this.details.item = Object.assign({}, details);
      this.details.item.legalForm = details.legalForm
        ? details.legalForm.value
        : undefined;
      this.details.item.status = details.status ? details.status.value : undefined;
      this.isShowDetails = true;
    },
  },
};
</script>

<style scoped>
.v-data-table {
  max-width: 100%;
  margin-bottom: 50px;
}
.hidden {
  display: none;
}
</style>
